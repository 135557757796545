<template>
	<el-form class="page" :model="vLinije" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>

		<el-row>
			<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
				<el-form-item :label="$t('vezaneLinije.naziv')" prop="naziv">
					<el-input v-model="vLinije.naziv" :maxLength="250"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="3">
				<el-form-item :label="$t('vezaneLinije.aktivno')" prop="aktivno">
					<el-switch
							v-model="vLinije.aktivno"
							active-value="DA"
							inactive-value="NE"
							:active-text="$t('global.aktivan')"
							:inactive-text="$t('global.neaktivan')"
							active-color="#13ce66"
							inactive-color="#ff4949"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item>
					<select-linije class="m--r-10" v-model="selectLinija" ref="linije"></select-linije>
					<el-button size="small" @click="dodajLiniju(selectLinija)">
						<el-icon class="el-icon-circle-plus"/>
						{{ $t('global.dodaj') }}
					</el-button>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :span="24">
				<el-form-item>
					<h2>{{$t('vezaneLinije.redosledVezivanja')}}</h2>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="8">
				<el-form-item :label="$t('vezaneLinije.polazak')">
					<el-card class="box-card">
						<draggable v-if="vLinije.linije_razlika.length" v-model="vLinije.linije_razlika">
							<transition-group>
								<div v-for="(linije, index) in vLinije.linije_razlika" :key="linije.id">
									<el-tag class="cursor-move width-full">
										<el-row :gutter="10">
											<el-col :span="2">
												<i v-if="vLinije.linije_razlika.length === 1" class="el-icon-caret-right"></i>
												<i v-else-if="index === 0" class="el-icon-caret-bottom"></i>
												<i v-else-if="vLinije.linije_razlika.length === index + 1" class="el-icon-caret-top"></i>
												<i v-else class="el-icon-d-caret"></i>
											</el-col>
											<el-col :span="12">
												<el-tooltip class="item" effect="dark" :content="linije.naziv_custom" placement="top">
													<span class="cursor-click">{{linije.naziv}}</span>
												</el-tooltip>
											</el-col>
											<el-col :span="2">
												<i class="el-icon-delete cursor-click hover-red" @click="ukloniLinijuIzSpiska(index)"></i>
											</el-col>
											<el-col :span="8">
												<el-input-number size="mini" :min="0" v-model="linije.dan_razlika"></el-input-number>
											</el-col>
										</el-row>
									</el-tag>
								</div>
							</transition-group>
						</draggable>
					</el-card>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="24" :md="12" :lg="10" :xl="8">
				<el-form-item :label="$t('vezaneLinije.povratak')">
					<el-card class="box-card">
						<div v-if="linijeReverse.length" :sortable="false">
							<div>
								<div v-for="(linije, index) in linijeReverse" :key="linije.id">
									<el-tag type="info" class="width-full">
										<el-row :gutter="10">
											<el-col :span="2">
												<i v-if="linijeReverse.length === 1" class="el-icon-caret-right"></i>
												<i v-else-if="index === 0" class="el-icon-caret-bottom"></i>
												<i v-else-if="linijeReverse.length === index + 1" class="el-icon-caret-top"></i>
												<i v-else class="el-icon-d-caret"></i>
											</el-col>
											<el-col :span="14">
												<el-tooltip class="item" effect="dark" :content="linije.naziv_custom" placement="top">
													<span class="cursor-click">{{okreniNazivLinije(linije.naziv)}}</span>
												</el-tooltip>
											</el-col>
											<el-col :span="8">
												<el-input-number size="mini" :min="0" v-model="find(linije.id).dan_razlika_povratak"></el-input-number>
											</el-col>
										</el-row>
									</el-tag>
								</div>
							</div>
						</div>
					</el-card>
				</el-form-item>
			</el-col>
		</el-row>

		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button type="success" :disabled="onSubmitLoading" v-loading="onSubmitLoading" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
import draggable from 'vuedraggable';
export default {
	name: 'vezane-linije-edit',
	data() {
		return {
			onSubmitLoading: false,
			fullscreenLoading: true,
			id: '',
			formCreate: '',
			vLinije: {
				naziv: '',
				linije_razlika: [],
				aktivno: 'NE'
			},
			linijeReverse: [],
			zadnjaLinija: null,
			selectLinija: null,
			selectVezaneLinije: [],
			loading: {
				linije: false
			},
			rules: {
				naziv: [{ required: true, max: 250, trigger: 'blur', message: this.$t('message.obavezno') }],
				aktivno: [{ required: true, message: this.$t('message.obavezno') }]
			}
		};
	},
	watch: {
		'vLinije.linije_razlika'(newData) {
			this.linijeReverse = _.cloneDeep(newData);
			this.linijeReverse.reverse();
		}
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('vezaneLinije.vezaneLinije')
		});

		this.$utils('stopLoadingAfter', [this.getVezaneLinije()]);
	},
	methods: {
		getVezaneLinije() {
			if (!this.formCreate) {
				return this.$get('vezaneLinije', { id: this.id }).then(data => {
					this.vLinije = data;
				});
			}
		},
		onSubmit() {
			return this.$refs.form.validate().then(() => {
				this.$utils('saveLoad');

				let data = _.cloneDeep(this.vLinije);

				data = JSON.stringify(data);

				if (this.formCreate)
					this.$save('vezaneLinije', data);
				else
					this.$update('vezaneLinije', { id: this.id }, data);
			}).catch((err) => {
				return err;
			});
		},
		ukloniLinijuIzSpiska(index) {
			this.vLinije.linije_razlika.splice(index, 1);
		},
		dodajLiniju(id) {
			if (!id)
				return false;

			let postoji = _.findIndex(this.vLinije.linije_razlika, function(o) {
				return o.id === id;
			});

			if (postoji >= 0) {
				// Alert obavestenje
				this.$alert(this.$t('alert.podatakPostoji'), '', {
					confirmButtonText: this.$t('global.uredu')
				});
			}
			else {
				let linija = this.$refs.linije.getLinija(id);
				this.selectLinija = null;

				this.$nextTick(() => {
					this.vLinije.linije_razlika.push({
						id: linija.id,
						naziv: linija.naziv,
						aktivno: linija.aktivno,
						dan_razlika: 0,
						dan_razlika_povratak: 0
					});
				});
			}
		},
		find(id) {
			return _.find(this.vLinije.linije_razlika, { 'id': id });
		},
		okreniNazivLinije(naziv) {
			let arrNaziv = naziv.split('-');
			return arrNaziv[1].trim() + ' - ' + arrNaziv[0].trim();
		}
	},
	components: {
		draggable,
		selectLinije: require('../../components/select/linije').default
	}
};
</script>
